import React from 'react';
import Layout from '../components/Layout';
const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <div className="wrapper">
        <header>
          <div className="inner">
            <h2>Loading and Unloading</h2>
            <p>
              Included with the basic package of all of our moving companies is
              a loading and unloading service. Because loading a moving truck
              isn't fun.
            </p>
          </div>
        </header>
        <div className="inner">
          <section id="two" className="wrapper alt spotlight style2">
            <h3 className="major">All of the labor</h3>
            <p>
              So what exactly does it mean that we do all of the loading and
              unloading? Just like it sounds. Leave everything right where it
              is. We will also take care of all dissassembly and reassembly.
              Seriously.{' '}
            </p>
            <div className="included-list-wrapper">
              <ul>
                <li>
                  {' '}
                  The movers dissassemble all the items which need to be taken
                  apart
                </li>
                <li>
                  The movers tag and bag every set of nuts, bolts, washers and
                  screws
                </li>
                <li> The movers wrap everything in moving blankets and pads</li>
                <li>The movers carefully carry all of your items outside</li>
                <li>The movers get everything up on to your moving truck</li>
                <li>
                  The movers neatly and carefully stack all of your items on
                  your moving truck
                </li>
                <li>
                  The movers give you a call and let you know when we will be
                  arrive with your delivery
                </li>
                <li>
                  The movers carefully unload all of your items off your moving
                  truck and bring them inside
                </li>
                <li>
                  {' '}
                  The movers bring their tools in and reasseble all of your
                  items
                </li>
                <li>
                  The movers place every single item exactly where you want it
                  set up
                </li>
                <li>
                  The movers hook up your washer, and dryer, double check that
                  all the nuts are tightened, and make sure you are happy{' '}
                </li>
                <li>
                  The movers double check the truck to make sure all of your
                  things have found their way home
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </section>
  </Layout>
);
export default IndexPage;
